import { useCallback } from 'react';
import { API_SERVER } from '../consts';
import useData from './useData';
import useLanguage from './useLanguage';

export const useContentData = (slug) => {
    const language = useLanguage();
    return useData(useCallback(async () => {
        const response = await fetch(`${API_SERVER}/api/content/${language}/${slug}/`);
        return await response.json();
    }, [slug, language]));
};
