export const AuthMenu = [
  {
    text: "header_section.login",
    to: "",
    subMenu: [
      {
        text: "header_section.login",
        to: "/auth/login",
      },
      {
        text: "header_section.register",
        to: "/auth/register",
      },
      {
        text: "header_section.forgot_password",
        to: "/auth/reset",
      },
    ],
  },
];

export const UserMenuItems = [
  { to: "/user/profile", text: "header_section.profile", icon: "user" },
  { to: "/user/change-password", text: "header_section.change_password", icon: "lock" },
  { to: "/user/kyc", text: "header_section.kyc_verification", icon: "form-validation" },
  { to: "/user/requests", text: "header_section.your_requests", icon: "coin-eur" },
  { to: "/user/logout", text: "header_section.logout", icon: "signout" },
];

export const UserMenu = [
  {
    text: "User",
    to: "",
    subMenu: UserMenuItems,
  },
];

export const MainMenuItems = [
  {
    text: "header_section.policies",
    to: "",
    subMenu: [
      {
        text: "header_section.privacy_policy",
        to: "/rules/privacy",
      },
      {
        text: "header_section.cookie_policy",
        to: "/rules/cookie",
      },
      {
        text: "header_section.terms_of_use",
        to: "/rules/terms",
      },
    ],
  },
  {
    text: "header_section.advantages",
    to: "/#advantages",
  },
  {
    text: "header_section.get_started",
    to: "/#getstarted",
  },
];
