import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Loader from "../../components/loader/loader";
import { useContentData } from "../../hooks/useContentData";
import PageLayout from "../../layout/page-layout/PageLayout";
import { Section, SectionHead } from "../../layout/section/Section";
import dayjs from "dayjs";

const ContentPage = ({ path }) => {
    const { data, loading } = useContentData(path);
    const navigate = useNavigate();

    if (loading) {
        return <Loader />
    }

    if (!data) {
        return navigate("/404");
    }

    return (
        <PageLayout>
            <Section className="rules-section">
                <Container>
                    <Row>
                        <Col>
                            <SectionHead>
                                <h2 className="title">{data.title}</h2>
                            </SectionHead>
                            <p>
                                <strong>Last Updated:</strong> {dayjs(data.updated_at).format("MMM D, YYYY")}
                            </p>

                            {data.content && <div dangerouslySetInnerHTML={{ __html: data.content }} />}
                        </Col>
                    </Row>
                </Container>
            </Section>
        </PageLayout>
    );
};

export default ContentPage;
