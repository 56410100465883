import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

const LineChart = ({ data, color = 'black', debounceDelay = 50 }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [chartSize] = useDebounce(dimensions, debounceDelay);

  const colorMap = {
    'Rising': '--color-rise',
    'Falling': '--color-fall',
    'Same': '--color-same',
    '': '--color-same',
  };

  color = getComputedStyle(document.documentElement).getPropertyValue(colorMap[color]);

  const drawChart = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const { width, height } = chartSize;

    // Очистить холст перед рисованием
    ctx.clearRect(0, 0, width, height);

    // Рассчитать масштаб по осям X и Y
    const maxDataValue = Math.max(...data);
    const minDataValue = Math.min(...data);
    const xStep = width / (data.length - 1);
    const yRange = maxDataValue - minDataValue;

    // Создаем градиент от цвета до прозрачности
    const gradient = ctx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, color);          // Цвет вверху
    gradient.addColorStop(0.7, `${color}00`);   // Прозрачный цвет внизу
    gradient.addColorStop(1, `${color}00`);   // Прозрачный цвет внизу

    // Начинаем рисование линии и области под ней
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;

    // Первая точка линии
    ctx.moveTo(0, height - ((data[0] - minDataValue) / yRange) * height);

    data.forEach((value, index) => {
      const x = index * xStep;
      const y = height - ((value - minDataValue) / yRange) * height;
      ctx.lineTo(x, y);
    });

    // Завершаем линию
    ctx.stroke();

    // Начинаем рисование заливки под линией
    ctx.lineTo(width, height);    // Опускаем линию вниз по X
    ctx.lineTo(0, height);        // Соединяем с начальной точкой по X
    ctx.closePath();              // Замыкаем путь для заливки

    // Применяем градиент и заливаем область
    ctx.fillStyle = gradient;
    ctx.fill();
  };

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    // Создание наблюдателя за изменениями размеров контейнера
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          const { width, height } = entry.contentRect;
          setDimensions({ width, height });
        }
      }
    });

    // Наблюдаем за изменениями контейнера
    resizeObserver.observe(container);

    // Отписка от наблюдателя при размонтировании
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      drawChart();
    }
  }, [data, color, chartSize]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <canvas ref={canvasRef} width={chartSize.width} height={chartSize.height} />
    </div>
  );
};

export default LineChart;

