import React from "react";
import { Link } from "react-router-dom";
import Head from "../../layout/head/Head";
import PageContainer from "../../layout/page-container/PageContainer";
import PageLayout from "../../layout/page-layout/PageLayout";

const Success = () => {
  return (
    <PageLayout>
      <Head title="Success" />
      <PageContainer>
        <div className="nk-block nk-block-middle nk-auth-body">
          <div className="nk-block">
            <div className="nk-block-content">
              <h4 className="nk-block-title">Thank you for submitting form</h4>
              <div className="nk-block-des text-success">
                <p>You can now sign in with your new password</p>
                <Link to={`${process.env.PUBLIC_URL}/pages/auths/auth-login`} className="btn btn-primary btn-lg">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </PageLayout>
  );
};
export default Success;
