import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import createRefresh from 'react-auth-kit/createRefresh';
import { API_SERVER } from '../consts';

export const refresh = createRefresh({
  interval: 60 * 10,
  refreshApiCallback: async (param) => {
    try {

      const decodedRefreshToken = jwtDecode(param.refreshToken);

      // Проверяем, истекает ли токен
      const response = await axios.post(`${API_SERVER}/auth/jwt/refresh/`, {
        refresh: param.refreshToken
      }, {
        headers: { 'Authorization': `Bearer ${param.authToken}` }
      })

      console.log("Refreshed", response.data);

      const decodedAuthToken = jwtDecode(response.data.access);

      return {
        isSuccess: true,
        newAuthToken: response.data.access,
        newAuthTokenExpireIn: Math.floor((decodedAuthToken.exp - Date.now() / 1000)),
        newRefreshTokenExpiresIn: Math.floor((decodedRefreshToken.exp - Date.now() / 1000)),
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});