import React from "react";
import { Outlet } from "react-router-dom";
import { AsideCard, Block, BlockContent, BlockHead, BlockTitle } from "../../components/Component";
import { MenuList } from "../../layout/menu/Menu";
import PageLayout from "../../layout/page-layout/PageLayout";
import { UserMenuItems } from "../../section/banner/BannerData";
import { useTranslation } from "react-i18next";

const UserPage = () => {
    const { t } = useTranslation();
    return (
        <PageLayout>
            <Block className="container-xl wide-xl">
                <BlockHead>
                    <BlockContent>
                        <BlockTitle className="mt-4" tag="h4">{t("profile_page.title")}</BlockTitle>
                    </BlockContent>
                </BlockHead>

                <AsideCard className="card-bordered" bodyClass="card-inner-lg" aside={<MenuList menuItems={UserMenuItems} />}>
                    <Outlet />
                </AsideCard>
            </Block>
        </PageLayout>
    );
};

export default UserPage;
