import React from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { languages } from "../../i18n";

const LangDropdown = ({ className, size, changeLanguage }) => {
  const changeLanguageHandler = (e, lang) => {
    e.preventDefault();
    changeLanguage(lang);
  };

  return (
    <DropdownMenu
      end
      className={`${size ? `dropdown-menu-${size}` : ""} ${
        className ? className : ""
      }`}
    >
      <ul className="language-list">
        {languages.map((item) => (
          <li key={item.code} className="language-item-wrapper">
            <DropdownItem
              tag="a"
              href="#item"
              className="language-item"
              onClick={(ev) => changeLanguageHandler(ev, item.code)}
            >
              <div className="language-item-inner">
                <img
                  src={"/assets/flags/" + item.code + ".svg"}
                  alt={item.name}
                  className="language-flag"
                />
                <span className="language-name">{item.name}</span>
              </div>
            </DropdownItem>
          </li>
        ))}
      </ul>
    </DropdownMenu>
  );
};

export default LangDropdown;
