import { useEffect, useState } from "react";
import i18next from "i18next";

const useLanguage = () => {
  const [language, setLanguage] = useState(i18next.language);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18next.on("languageChanged", handleLanguageChange);

    return () => {
      i18next.off("languageChanged", handleLanguageChange);
      
    };
  }, []);

  return language;
};

export default useLanguage;
