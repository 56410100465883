import React, { Suspense, useContext, useEffect } from "react";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import {
  Link,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/loader/loader";
import { AppContext, AppProvider } from "./context/AppContext";
import "./i18n";
import Activate from "./pages/auth/Activate";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordSent from "./pages/auth/ForgotPasswordSent";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPasswordConfirm from "./pages/auth/ResetPasswordConfirm";
import Success from "./pages/auth/Success";
import ContentPage from "./pages/content/ContentPage";
import Error404Classic from "./pages/error/404-classic";
import ExchangeFormPage from "./pages/exchange/ExchangeFormPage";
import ExchangeReqestFormPage from "./pages/exchange/ExchangeRequestPage";
import HomePage from "./pages/HomePage";
import ChangePassword from "./pages/user/ChangePassword";
import ExchangeRequests from "./pages/user/ExchangeRequests";
import KYC from "./pages/user/KYC";
import Logout from "./pages/user/Logout";
import Profile from "./pages/user/Profile";
import UserPage from "./pages/user/User";
import { refresh } from "./utils/Refresh";

const store = createStore({
  authName: "_auth",
  authType: "localstorage",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
  refresh: refresh,
});

const AppGuard = () => {
  const { isAuthReady } = useContext(AppContext);

  if (!isAuthReady) {
    return <Loader />;
  }

  return <Outlet />;
};

const Guard = () => {
  const { isAppReady, isReady } = useContext(AppContext);

  if (!isAppReady || !isReady) {
    return <Loader />;
  }

  return <Outlet />;
};

const AuthGuard = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthReady } = useContext(AppContext);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/auth/login", { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location]);

  if (!isAuthReady) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />; // Рендер вложенных маршрутов
};
function App() {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.className =
      "nk-body bg-white npc-landing no-touch nk-nio-theme";
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="nk-app-root">
        <AuthProvider store={store}>
          <AppProvider>
            <Routes>
              <Route path={`${process.env.PUBLIC_URL}/rules`}>
                <Route
                  path="privacy"
                  element={<ContentPage path="rules-privacy" />}
                ></Route>
                <Route
                  path="cookie"
                  element={<ContentPage path="rules-cookies" />}
                ></Route>
                <Route
                  path="terms"
                  element={<ContentPage path="rules-terms" />}
                ></Route>
              </Route>
              <Route path="auth" element={<AppGuard />}>
                <Route path="activate/:uid/:token" element={<Activate />}></Route>
                <Route path="confirm/:uid/:token" element={<ResetPasswordConfirm />}></Route>
                <Route path="success" element={<Success />}></Route>
                <Route path="reset" element={<ForgotPassword />}></Route>
                <Route path="reset-sent" element={<ForgotPasswordSent />}></Route>
                <Route path="register" element={<Register />}></Route>
                <Route path="login" element={<Login />}></Route>
              </Route>
              <Route path="user" element={<AuthGuard />}>
                <Route path="" element={<UserPage />}>
                  <Route path="logout" element={<Logout />}></Route>
                  <Route path="profile" element={<Profile />}></Route>
                  <Route path="change-password" element={<ChangePassword />}></Route>
                  <Route path="kyc" element={<KYC />}></Route>
                  <Route path="requests" element={<ExchangeRequests />}></Route>
                </Route>
              </Route>
              <Route
                index
                path={`${process.env.PUBLIC_URL}`}
                element={<HomePage />}
              />
              <Route element={<Guard />}>
                <Route
                  path={`${process.env.PUBLIC_URL}/exchange/:from/:to`}
                  element={<ExchangeFormPage />}
                ></Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/request/:from/:to`}
                  element={<ExchangeReqestFormPage />}
                ></Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/exchange`}
                  element={<ExchangeFormPage />}
                ></Route>
              </Route>
              <Route path="*" element={<Error404Classic />}></Route>
            </Routes>
          </AppProvider>
        </AuthProvider>
        <ToastContainer />
        <CookieConsent
          disableStyles
          buttonClasses="btn btn-primary"
          containerClasses="alert-fill alert alert-light fade show сookie-bar"
          contentClasses="text"
          buttonText={t("cookie_banner.accept_btn")}
        >
          {t("cookie_banner.text")}{" "}
          <Link to="/rules/cookie">{t("cookie_banner.cookie_policy")}</Link>.
        </CookieConsent>
      </div>
    </Suspense>
  );
}

export default App;
