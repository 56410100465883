import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Spinner } from "reactstrap";
import ErrorList from "../../components/form/ErrorList";
import { Icon } from "../../components/icon/Icon";
import { API_SERVER } from "../../consts";
import Head from "../../layout/head/Head";
import PageLayout from "../../layout/page-layout/PageLayout";
import { checkError } from "../../utils/Utils";
import apiClient from "../../services/ApiClient";

const Register = () => {
  const { t, i18n } = useTranslation();
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      language: i18n.language
    }
  });
  const [errorVal, setErrorVal] = useState("");
  const navigate = useNavigate();

  const handleFormSubmit = (formData) => {
    setLoading(true);

    apiClient
      .post(`${API_SERVER}/auth/users/`, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          navigate("/auth/login");
          toast.success(t('register_page.success'), {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: false,
          });
        }
      })
      .catch((err) => {
        checkError({ err, setErrorVal, setError });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout>
      <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
        <div className="card-preview card-bordered">
          <div className="card-inner card-inner-lg">
            <Head title="Forgot-Password" />
            <div className="nk-block-head">
              <div className="nk-block-content">
                <h5 className="nk-block-title page">
                  {t("register_page.title")}
                </h5>
                <div className="nk-block-des">
                  <p>{t("register_page.text")}</p>
                </div>
              </div>
            </div>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon" fade={false}>
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <form
              className="is-alter"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    {t("register_page.email")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="default-01"
                    autoComplete="email"
                    {...register("email", { required: true })}
                    className="form-control-lg form-control"
                    placeholder={t("register_page.enter_email")}
                  />
                  <ErrorList errors={errors.email} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    {t("register_page.password")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right password-switch ${passState ? "is-hidden" : "is-shown"
                      }`}
                  >
                    <Icon name="eye" className="password-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="password-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    {...register("password", {
                      required: t("register_page.field_required"),
                    })}
                    placeholder={t("register_page.enter_password")}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"
                      }`}
                  />
                  <ErrorList errors={errors.password} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="re_password">
                    {t("register_page.repeat_password")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#re_password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right password-switch ${passState ? "is-hidden" : "is-shown"
                      }`}
                  >
                    <Icon name="eye" className="password-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="password-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="re_password"
                    autoComplete="new-password"
                    {...register("re_password", {
                      required: t("register_page.field_required"),
                    })}
                    placeholder={t("register_page.repeat_your_password")}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"
                      }`}
                  />
                  <ErrorList errors={errors.re_password} />
                </div>
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  className="btn-block"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t("register_page.register_btn")
                  )}
                </Button>
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              {t("register_page.have_account")}{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth/login`}>
                <strong>{t("register_page.sign_in")}</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default Register;
