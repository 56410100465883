import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Alert, Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { BlockContent, BlockDes, BlockHead, BlockTitle, FormInput } from "../../components/Component";
import { countries } from "../../data/countries";
import apiClient from "../../services/ApiClient";
import { checkError } from "../../utils/Utils";


const Profile = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errorVal, setErrorVal] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

    const fetchUserData = () => {
        setLoading(true);
        setErrorVal("");

        apiClient
            .get(`/auth/users/me/`)
            .then((res) => {
                reset(res.data); // Populate form with user data
            })
            .catch((err) => {
                setErrorVal(err.response?.data?.detail || "Failed to load profile data.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const onFormSubmit = (formData) => {
        setLoading(true);
        setErrorVal("");
        setSuccessMessage("");

        apiClient
            .put(`/auth/users/me/`, formData)
            .then((res) => {
                setSuccessMessage(t("profile_page.update_profile_success_msg"));
                toast.success(t("profile_page.update_profile_success_msg"), {
                    position: "top-right",
                    autoClose: true,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: false,
                });
                fetchUserData();
            })
            .catch((err) => {
                checkError({ err, setErrorVal, setError });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading) {
        return <Spinner color="primary" />
    }

    return (
        <>
            <BlockHead>
                <BlockContent>
                    <BlockTitle tag="h4">{t("profile_page.edit_profile")}</BlockTitle>
                    <BlockDes>
                        <p>{t("profile_page.text")}</p>
                    </BlockDes>
                </BlockContent>
            </BlockHead>

            {errorVal && (
                <Alert color="danger" fade={false} className="mt-3">
                    {errorVal}
                </Alert>
            )}
            {successMessage && (
                <Alert color="success" fade={false} className="mt-3">
                    {successMessage}
                </Alert>
            )}

            <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                {/* First Name and Last Name */}
                <Row>
                    <Col md={6}>
                        <FormInput name="first_name" label={t("profile_page.first_name")} errors={errors} register={register} />
                    </Col>
                    <Col md={6}>
                        <FormInput name="last_name" label={t("profile_page.last_name")} errors={errors} register={register} />
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormInput name="phone" label={t("profile_page.phone")} errors={errors} register={register} />
                    </Col>
                    <Col md={6}>
                        {/* Birth Date */}
                        <FormInput type="date" name="birth_date" label={t("profile_page.birth_date")} errors={errors} register={register} />
                    </Col>
                </Row>


                {/* Country and State */}
                <Row>
                    <Col md={6}>
                        <FormInput type="select" name="country_code" label={t("profile_page.country")} errors={errors} register={register} className="form-select-lg">
                            {Object.entries(countries).map(([code, name]) => (
                                <option key={code} value={code}>{name}</option>
                            ))}
                        </FormInput>

                    </Col>
                    <Col md={6}>
                        <FormInput name="state" label={t("profile_page.state")} errors={errors} register={register} />
                    </Col>
                </Row>

                {/* Address1 */}
                <FormInput name="address1" label={t("profile_page.address_first_line")} errors={errors} register={register} />

                {/* Address2 */}
                <FormInput name="address2" label={t("profile_page.address_second_line")} errors={errors} register={register} />

                {/* Language */}
                <FormInput type="select" name="language" label={t("profile_page.language")} errors={errors} register={register} className="form-select-lg" >
                    <option value="">{t("profile_page.select_language")}</option>
                    <option value="en">{t("profile_page.english")}</option>
                    <option value="ru">{t("profile_page.russian")}</option>
                    {/* Add more languages as needed */}
                </FormInput>


                {/* Submit Button */}
                <FormGroup>
                    <Button size="lg" className="btn-block" type="submit" color="primary">
                        {loading ? <Spinner size="sm" color="light" /> : t("profile_page.update_profile")}
                    </Button>
                </FormGroup>
            </form>

        </>
    );
};

export default Profile;
