import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "../components/button/Button";
import ActivePairs from "../components/exchange/ActivePairs";
import {
  HeaderCaption,
  HeaderText,
  HeaderTitle,
} from "../components/headerCaption/HeaderCaption";
import { Paragraph } from "../components/textBlock/TextBlock";
import { Section } from "../layout/section/Section";
import { useTranslation } from "react-i18next";

const WelcomeSection = (props) => {
  const { t } = useTranslation();
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="flex-row-reverse justify-content-between g-gs">
          <Col lg="6" className="mb-3">
            <ActivePairs showBoth={true} />
          </Col>
          <Col lg="6">
            <HeaderCaption>
              {/* <HeaderTitle>Welcome to incwt.com</HeaderTitle> */}
              <HeaderTitle>In Crypto We Trust</HeaderTitle>
              <HeaderText className="pe-5">
                <p className="welcome-subtitle">
                  {t("welcome_section.subtitle")}
                </p>
                <Paragraph>{t("welcome_section.text")}</Paragraph>
              </HeaderText>
              <ul className="header-action btns-inline">
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/exchange`}
                    rel="noreferrer"
                    className="btn-primary btn-lg"
                  >
                    <span>{t("welcome_section.start_btn")}</span>
                  </Link>
                </li>
              </ul>
            </HeaderCaption>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default WelcomeSection;
