import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useHistoricalTrendData } from "../../hooks/useQuoteData";
import { NumberField } from "../fields/NumberField";
import LineChart from "../LineChart/LineChart";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../table/DataTable";

const TablePairItem = ({ pair, trend, quoteAsk, quoteBid, showBoth }) => {
  const navigate = useNavigate();
  let color = 'Same';
  if (quoteAsk && quoteBid && trend && trend[0]) {
    color = trend[0] > (quoteAsk.rate + quoteBid.rate) / 2 ? 'Rising' : 'Falling';
  }

  return (
    <DataTableItem
      onClick={() => {
        navigate(`/exchange/${pair.base.code}/${pair.quote.code}`);
      }}
      className={"nk-tb-item nk-tb-hover"}
    >
      <DataTableRow className="nk-tb-orders-type">
        <ul className="icon-overlap">
          <li>
            <img
              className="icon icon-circle "
              src={`/assets/icons/${pair.base.icon}.svg`}
              alt={pair.base.name}
            />
          </li>
          <li>
            <img
              className="icon icon-circle"
              src={`/assets/icons/${pair.quote.icon}.svg`}
              alt={pair.base.name}
            />
          </li>
        </ul>
      </DataTableRow>
      <DataTableRow>
        <span className="tb-lead">
          {pair.base.code}/{pair.quote.code}
        </span>
      </DataTableRow>

      {showBoth && (
        <>
          <DataTableRow>
            <span className="pair-price">
              <NumberField
                value={quoteAsk?.rate}
                className={"color-" + quoteAsk?.dir}
                digits={pair.base.decimals}
              />
            </span>
          </DataTableRow>
          <DataTableRow>
            <span className="pair-price">
              <NumberField
                value={quoteBid?.rate}
                className={"color-" + quoteBid?.dir}
                digits={pair.base.decimals}
              />
            </span>
          </DataTableRow>
        </>
      )}
      {!showBoth && (
        <DataTableRow>
          <span className="pair-price">
            <NumberField
              value={quoteAsk?.rate}
              className={"color-" + quoteAsk?.dir}
              digits={pair.base.decimals}
            />
          </span>
        </DataTableRow>
      )}
      <DataTableRow size="sm">
        <div className="pair-chart">
          {trend && <LineChart data={trend} color={color} />}
        </div>
      </DataTableRow>
    </DataTableItem>
  );
};

const ActivePairs = ({ showBoth }) => {
  const { t } = useTranslation();
  const { isAppReady, pairs: activePairs, quotes, isReady } = useContext(AppContext);
  const pairIds = useMemo(
    () =>
      activePairs?.map((pair) => `${pair.base.code}/${pair.quote.code}`) || [],
    [activePairs]
  );
  const { data: trend } = useHistoricalTrendData(pairIds, isReady);
  if (!isAppReady || !trend) return <div>Loading...</div>;

  return (
    <DataTable className="active-pairs-table">
      <DataTableBody bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow></DataTableRow>
          <DataTableRow>
            <span>{t("active_pairs_table.pair")}</span>
          </DataTableRow>
          {showBoth && (
            <>
              <DataTableRow>
                <span>{t("active_pairs_table.buy")}</span>
              </DataTableRow>
              <DataTableRow>
                <span>{t("active_pairs_table.sell")}</span>
              </DataTableRow>
            </>
          )}
          {!showBoth && (
            <DataTableRow>
              <span>{t("active_pairs_table.price")}</span>
            </DataTableRow>
          )}
          <DataTableRow size="sm">
            <span>{t("active_pairs_table.trend")}</span>
          </DataTableRow>
        </DataTableHead>
        {activePairs &&
          activePairs.map((pair, index) => (
            <TablePairItem
              key={`${pair.base.code}/${pair.quote.code}`}
              showBoth={showBoth}
              pair={pair}
              trend={trend[`${pair.base.code}/${pair.quote.code}`]}
              quoteAsk={quotes.get(`${pair.base.code}/${pair.quote.code}`)}
              quoteBid={quotes.get(`${pair.quote.code}/${pair.base.code}`)}
            />
          ))}
      </DataTableBody>
    </DataTable>
  );
};

export default ActivePairs;
