import classNames from "classnames";
import { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Icon } from "../Component";

export const FormInput = ({ register, name, errors, label, className, ...rest }) => {
    const { ref, ...registerField } = register(name);
    const inputClassName = classNames(
        "form-control-lg", " form-control",
        {
            [`${className}`]: className,
        });

    return <FormGroup>
        <Label for={name}>{label}</Label>
        <Input innerRef={ref} {...registerField} {...rest} className={inputClassName}>{rest.children}</Input>
        {errors[name] && <span className="text-danger">{errors[name].message}</span>}
    </FormGroup>
};

export const FormPassword = ({ register, name, errors, label, className, ...rest }) => {
    const { ref, ...registerField } = register(name);
    const inputClassName = classNames(
        "form-control-lg", " form-control",
        {
            [`${className}`]: className,
        });

    const [passState, setPassState] = useState(false);

    return <FormGroup>
        <Label for={name}>{label}</Label>
        <div className="form-control-wrap">
            <a
                href="#password"
                onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right password-switch ${passState ? "is-hidden" : "is-shown"}`}
            >
                <Icon name="eye" className="password-icon icon-show"></Icon>

                <Icon name="eye-off" className="password-icon icon-hide"></Icon>
            </a>
            <Input type={passState ? "text" : "password"} innerRef={ref} {...registerField} {...rest} className={inputClassName}>{rest.children}</Input>
        </div>
        {errors[name] && <span className="text-danger">{errors[name].message}</span>}
    </FormGroup>
};