import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  Service,
  ServiceIcon,
  ServiceText,
} from "../components/service/Service";
import {
  Analytics,
  Paper,
  Responsive,
  Sharing,
  Target,
} from "../components/svg/Icon";
import {
  Section,
  SectionContant,
  SectionHead,
} from "../layout/section/Section";
import { useTranslation } from "react-i18next";

const AdvantagesSection = (props) => {
  const { t } = useTranslation();
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="justify-content-center text-center">
          <Col xs="9" sm="7" md="6">
            <SectionHead>
              <h2 className="title">{t("advantages_section.title")}</h2>
            </SectionHead>
          </Col>
        </Row>
        <SectionContant>
          <Row className="gy-gs justify-content-center text-center">
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-info">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-info">
                    <Paper />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      {t("advantages_section.regulated_compliant_title")}
                    </h5>
                    <p>{t("advantages_section.regulated_compliant_text")}</p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-primary">
                    <Responsive />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      {t("advantages_section.flexible_exchange_title")}
                    </h5>
                    <div>
                      <ul>
                        <li>
                          <strong>
                            {t(
                              "advantages_section.online_registration_and_exchange_title"
                            )}
                          </strong>
                          {t(
                            "advantages_section.online_registration_and_exchange_text"
                          )}
                        </li>
                        <li>
                          <strong>
                            {t("advantages_section.in_person_services_title")}
                          </strong>{" "}
                          {t("advantages_section.in_person_services_text")}
                        </li>
                      </ul>
                    </div>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-danger">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-danger">
                    <Analytics />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      {t("advantages_section.cash_exchange_title")}
                    </h5>
                    <p>{t("advantages_section.cash_exchange_text")}</p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
          </Row>
          <Row className="gy-gs justify-content-center text-center">
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4 after-bg-info">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-info">
                    <Sharing />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      {t(
                        "advantages_section.secure_and_private_transactions_title"
                      )}
                    </h5>
                    <p>
                      {t(
                        "advantages_section.secure_and_private_transactions_text"
                      )}
                    </p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
            <Col xs="10" sm="6" lg="4">
              <Service className="card card-full service-s4">
                <div className="card-inner">
                  <ServiceIcon className="styled-icon styled-icon-6x text-primary">
                    <Target />
                  </ServiceIcon>
                  <ServiceText>
                    <h5 className="title text-dark">
                      {t("advantages_section.live_exchange_rates_title")}
                    </h5>
                    <p>{t("advantages_section.live_exchange_rates_text")}</p>
                  </ServiceText>
                </div>
              </Service>
            </Col>
          </Row>
        </SectionContant>
      </Container>
    </Section>
  );
};

export default AdvantagesSection;
