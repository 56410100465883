import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ImageBlock } from "../components/images/Images";
import { Review, ReviewContent, ReviewText } from "../components/review/Review";
import { TextBlock, TitleH2 } from "../components/textBlock/TextBlock";
import ThumbImg1 from "../images/gfx/a.png";
import { Section } from "../layout/section/Section";
import { useTranslation } from "react-i18next";

const CommitmentSection = (props) => {
  const { t } = useTranslation();
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="align-items-center justify-content-lg-between g-gs">
          <Col lg="5">
            <ImageBlock className="img-block-s1 left">
              <img src={ThumbImg1} alt="Thumb img" />
            </ImageBlock>
          </Col>
          <Col lg="6">
            <TextBlock className="me-xl-5">
              <TitleH2>{t("commitment_section.title")}</TitleH2>
              <h4>{t("commitment_section.subtitle")}</h4>
              <Review>
                <ReviewContent>
                  <ReviewText>
                    <ul className="list list-lg list-purple list-checked-circle gy-4">
                      <li className="ps-5">
                        {t("commitment_section.first_commitment_text")}
                      </li>
                      <li className="ps-5">
                        {t("commitment_section.second_commitment_text")}
                      </li>
                      <li className="ps-5">
                        {t("commitment_section.third_commitment_text")}
                      </li>
                    </ul>
                  </ReviewText>
                </ReviewContent>
              </Review>
              <p className="note-text">
                <span className="blue-text">
                  {" "}
                  {t("commitment_section.note_title")}
                </span>{" "}
                {t("commitment_section.note_text")}
              </p>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default CommitmentSection;
