import React,{useState} from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import Scroll from "react-scroll";
import { Icon } from '../../components/Component';
import { useTranslation } from 'react-i18next';

const ScrollLink = Scroll.Link;


export const MenuList = ({ menuItems }) => {
    const { t } = useTranslation();
    return (
        <ul className="link-list-menu">
          {menuItems.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.to}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {item.icon && <Icon name={item.icon} />}<span>{t(item.text)}</span>
              </NavLink>
            </li>
          ))}
        </ul>
    );
  };

const Menu = ({data, ...props}) =>{   
    return (
        <ul className={['menu-list', props.className ? props.className : null].join(' ')}>
            {data.map((data,index) =>
                <MenuItem key={index} to={data.to} text={data.text} submenu={data.subMenu}>
                </MenuItem> 
            )}
        </ul>
    )
}

const MenuItem = ({data, ...props}) =>{
    const [active , setActive] = useState(false);
    const { t } = useTranslation();
    return (
        <li className={['menu-item', props.submenu ? 'has-sub' : null, active ? "active" : ""].join(' ')} onClick={() => props.submenu ? setActive(!active) : null }>
            <MenuLink to={props.to} submenu={props.submenu}>
                {props.icon && <Icon name={props.icon} />}
                <span>{t(props.text)}</span>
            </MenuLink>
            {props.submenu && <MenuSub submenu={props.submenu} active={active}/>}
        </li>
    )
}

export const MenuLink = ({data, ...props}) => {
    let location = useLocation();
    if (props.to.split("")[1] === "#" & location.pathname === props.to.split("#")[0]){
        return (
        <ScrollLink
            to={props.to.slice(2)}
            href="#"
            spy={true} 
            smooth={true}  
            duration={50}
            className="menu-link">
                {props.children}
        </ScrollLink>
        )
    }
    else {
        if (props.submenu){
            return (
                <a href="toggle" onClick={(ev) => ev.preventDefault()} className="menu-link">
                    {props.children}
                </a>
            )
        } else {
            return (
                <Link to={process.env.PUBLIC_URL + props.to} className="menu-link">{props.children}</Link>
            )
        }
    }
}

const MenuSub = ({data, ...props}) => {
    const { t } = useTranslation();
    return (
        <div className="menu-sub" style={{display : props.active ? "block" : "none"}}>
            <ul className="menu-list">
                {props.submenu.map((data, index) =>
                    <MenuItem key={index} to={data.to} text={t(data.text)} submenu={data.subMenu} icon={data.icon}></MenuItem>
                )}
            </ul>
        </div>
    )
}


export default Menu
