import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Spinner } from "reactstrap";
import ErrorList from "../../components/form/ErrorList";
import { Icon } from "../../components/icon/Icon";
import { API_SERVER } from "../../consts";
import PageLayout from "../../layout/page-layout/PageLayout";
import { checkError } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/ApiClient";

const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setErrorVal] = useState("");
  const signIn = useSignIn();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const onFormSubmit = (formData) => {
    setLoading(true);
    setErrorVal("");

    apiClient
      .post(`${API_SERVER}/auth/jwt/create/`, formData)
      .then((res) => {
        if (res.status === 200) {
          const decoded = jwtDecode(res.data.access);
          if (
            signIn({
              auth: {
                token: res.data.access,
                type: "Bearer",
              },
              refresh: res.data.refresh,
              userState: decoded?.user,
            })
          ) {
            const redirectPath = location.state?.from?.pathname || "/";
            navigate(redirectPath);
          }
        }
      })
      .catch((err) => {
        checkError({ err, setErrorVal, setError });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout>
      <div className="nk-block nk-block-middle nk-auth-body wide-xs">
        <div className="card-preview card-bordered">
          <div className="card-inner card-inner-lg">
            <div className="nk-block-head">
              <div className="nk-block-content">
                <h5 className="nk-block-title page">{t("login_page.title")}</h5>
                <div className="nk-block-des">
                  <p>{t("login_page.text")}</p>
                </div>
              </div>
            </div>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon" fade={false}>
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form
              className="is-alter"
              onSubmit={handleSubmit(onFormSubmit)}
              autoComplete="on"
            >
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="email">
                    {t("login_page.email")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    tabIndex="1"
                    autoComplete="email"
                    type="text"
                    id="email"
                    {...register("email", {
                      required: t("login_page.field_required"),
                    })}
                    placeholder={t("login_page.enter_email")}
                    className="form-control-lg form-control"
                  />
                  <ErrorList errors={errors.email} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    {t("login_page.password")}
                  </label>
                  <Link
                    className="link link-primary link-sm"
                    to={`${process.env.PUBLIC_URL}/auth/reset`}
                  >
                    {t("login_page.forgot_password")}
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right password-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="password-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="password-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    tabIndex="2"
                    autoComplete="current-password"
                    type={passState ? "text" : "password"}
                    id="password"
                    {...register("password", {
                      required: t("login_page.field_required"),
                    })}
                    placeholder={t("login_page.enter_password")}
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  <ErrorList errors={errors.password} />
                </div>
              </div>
              <div className="form-group">
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                  tabIndex="3"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t("login_page.sign_in_btn")
                  )}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              {t("login_page.no_account")}
              {"  "}
              <Link to={`${process.env.PUBLIC_URL}/auth/register`}>
                {t("login_page.register")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default Login;
