import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Spinner } from "reactstrap";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewCard
} from "../../components/Component";
import ErrorList from "../../components/form/ErrorList";
import { API_SERVER } from "../../consts";
import Head from "../../layout/head/Head";
import PageLayout from "../../layout/page-layout/PageLayout";
import { checkError } from "../../utils/Utils";
import apiClient from "../../services/ApiClient";

const ResetPasswordConfirm = () => {
  const { t } = useTranslation();
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: {
      uid: params.uid,
      token: params.token
    }
  });
  const [errorVal, setErrorVal] = useState("");
  const navigate = useNavigate();


  const handleFormSubmit = (formData) => {
    setLoading(true);
    setErrorVal("");

    apiClient.post(`${API_SERVER}/auth/users/reset_password_confirm/`, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          toast.success(t("reset_password_page.success"), {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: false,
          });
          navigate('/auth/login');
        }
      }).catch((err) => {
        checkError({ err, setErrorVal, setError, errorKey: 'token' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return <PageLayout>
    <Head title="Change Password" />
    <Block className="nk-block-middle nk-auth-body  wide-xs">
      <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">Change Password</BlockTitle>
          </BlockContent>
        </BlockHead>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon" fade={false}>
              <Icon name="alert-circle" /> {errorVal}
            </Alert>
          </div>
        )}
        <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="new_password">
                New Password
              </label>
            </div>
            <div className="form-control-wrap">
              <a
                href="#new_password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right password-switch ${passState ? "is-hidden" : "is-shown"}`}
              >
                <Icon name="eye" className="password-icon icon-show"></Icon>

                <Icon name="eye-off" className="password-icon icon-hide"></Icon>
              </a>
              <input
                type={passState ? "text" : "password"}
                id="new_password"
                {...register('new_password', { required: "This field is required" })}
                placeholder="Enter your new password"
                autoComplete="new-password"
                className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`} />
              <ErrorList errors={errors.new_password} />
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="re_new_password">
                Repeat New Password
              </label>
            </div>
            <div className="form-control-wrap">
              <a
                href="#re_new_password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right password-switch ${passState ? "is-hidden" : "is-shown"}`}
              >
                <Icon name="eye" className="password-icon icon-show"></Icon>

                <Icon name="eye-off" className="password-icon icon-hide"></Icon>
              </a>
              <input
                type={passState ? "text" : "password"}
                id="re_new_password"
                autoComplete="new-password"
                {...register('re_new_password', { required: "This field is required" })}
                placeholder="Repeat your new password"
                className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`} />
              <ErrorList errors={errors.re_new_password} />
            </div>
          </div>
          <div className="form-group">
            <Button type="submit" color="primary" size="lg" className="btn-block">
              {loading ? <Spinner size="sm" color="light" /> : "Change Password"}
            </Button>
          </div>
        </form>
        <div className="form-note-s2 text-center pt-4">
          {" "}
          Already have an account?{" "}
          <Link to={`${process.env.PUBLIC_URL}/auth/login`}>
            <strong>Sign in instead</strong>
          </Link>
        </div>
        <div className="text-center pt-4 pb-3">
          <h6 className="overline-title overline-title-sap">
            <span>OR</span>
          </h6>
        </div>
        <ul className="nav justify-center gx-8">
          <li className="nav-item">
            <a
              className="nav-link"
              href="#socials"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              Facebook
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#socials"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              Google
            </a>
          </li>
        </ul>
      </PreviewCard>
    </Block>
  </PageLayout>;
};
export default ResetPasswordConfirm;
