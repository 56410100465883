import React from "react";

const ErrorList = ({ errors }) => {
    if (!errors) return null;

    const { message } = errors;
    if (!message) return null;
    const messages = Array.isArray(message) ? message : [message]

    return (
        <div className="invalid">
            <ul className="error-list">
                {messages.map((msg, index) => (
                    <li key={index}>{msg}</li>
                ))}
            </ul>
        </div>
    );
};

export default ErrorList;