import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button } from "reactstrap";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon } from "../../components/Component";
import { API_SERVER } from "../../consts";
import Head from "../../layout/head/Head";
import PageLayout from "../../layout/page-layout/PageLayout";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/ApiClient";

const Activate = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const formData = {
    uid: params.uid,
    token: params.token
  };

  useEffect(() => {
    setLoading(true);
    apiClient.post(`${API_SERVER}/auth/users/activation/`, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          navigate('/auth/login');
          toast.success(t('register_page.activated'), {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: false,
          });
        }
      }).catch((err) => {
        const { data } = err.response;
        if (data.detail) {
          setError(data.detail);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <PageLayout>
      <Head title="Activation" />
      <Block className="nk-block-middle nk-auth-body">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">Activating account</BlockTitle>
            {error &&
              <BlockDes className="text-error">

                <div className="mb-3">
                  <Alert color="danger" className="alert-icon" fade={false}>
                    <Icon name="alert-circle" /> {error}
                  </Alert>
                </div>

                <Link to={`${process.env.PUBLIC_URL}/auth/reset`}>
                  <Button color="primary" size="lg">
                    Resend activation code
                  </Button>
                </Link>
              </BlockDes>
            }
          </BlockContent>
        </BlockHead>
      </Block>
    </PageLayout>
  );

};
export default Activate;
