import { useCallback } from 'react';
import useData from './useData';
import apiClient from '../services/ApiClient';

export const useUserData = () => {
    return useData(
        useCallback(async () => {
            const response = await apiClient.get('/auth/users/me/');
            return response.data;
        }, [])
    );
};
