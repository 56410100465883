import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Spinner } from "reactstrap";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewAltCard,
} from "../../components/Component";
import ErrorList from "../../components/form/ErrorList";
import { API_SERVER } from "../../consts";
import PageLayout from "../../layout/page-layout/PageLayout";
import { checkError } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import apiClient from "../../services/ApiClient";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [errorVal, setErrorVal] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFormSubmit = (formData) => {
    setLoading(true);
    setErrorVal("");
    setMessage("");

    apiClient
      .post(`${API_SERVER}/auth/users/reset_password/`, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          navigate("/auth/reset-sent");
        }
      })
      .catch((err) => {
        checkError({ err, setErrorVal, setError });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <PageLayout>
      <Block className="nk-block nk-block-middle nk-auth-body wide-xs">
        <PreviewAltCard bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">{t("reset_password_page.title")}</BlockTitle>
              <BlockDes>
                <p>{t("reset_password_page.text")}</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon" fade={false}>
                <Icon name="alert-circle" /> {errorVal}
              </Alert>
            </div>
          )}
          {message && (
            <div className="mb-3">
              <Alert color="success" className="alert-icon" fade={false}>
                <Icon name="alert-circle" /> {message}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  {t("reset_password_page.email")}
                </label>
              </div>

              <div className="form-control-wrap">
                <input
                  tabIndex="1"
                  autoComplete="username"
                  type="text"
                  id="email"
                  {...register("email", { required: t("reset_password_page.field_required") })}
                  placeholder={t("reset_password_page.enter_email")}
                  className="form-control-lg form-control"
                />
                <ErrorList errors={errors.email} />
              </div>
            </div>
            <div className="form-group">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
                tabIndex="3"
              >
                {loading ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  t("reset_password_page.reset_password_btn")
                )}
              </Button>
            </div>
          </Form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth/login`}>
              <strong>{t("reset_password_page.back_to_login")}</strong>
            </Link>
          </div>
        </PreviewAltCard>
      </Block>
    </PageLayout>
  );
};
export default ForgotPassword;
