import React, { useState } from "react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Alert, Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle
} from "../../components/Component";
import { FormPassword } from "../../components/form/FormInput";
import apiClient from "../../services/ApiClient";
import { checkError } from "../../utils/Utils";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorVal, setErrorVal] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const user = useAuthUser();
  const navigate = useNavigate();

  const onFormSubmit = (formData) => {
    setLoading(true);
    setErrorVal("");
    setSuccessMessage("");

    apiClient
      .post(`/auth/users/set_password/`, formData)
      .then(() => {
        setSuccessMessage(t("change_password_page.update_password_success_msg"));
        toast.success(t("change_password_page.update_password_success_msg"), {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
        navigate("/user/profile");
      })
      .catch((err) => {
        checkError({ err, setErrorVal, setError });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">{t("change_password_page.title")}</BlockTitle>
          <BlockDes>
            <p>{t("change_password_page.text")}</p>
          </BlockDes>
        </BlockContent>
      </BlockHead>

      {errorVal && (
        <Alert color="danger" fade={false} className="mt-3">
          {errorVal}
        </Alert>
      )}
      {successMessage && (
        <Alert color="success" fade={false} className="mt-3">
          {successMessage}
        </Alert>
      )}

      <form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
        {/* Current Password */}
        <input
          id="username"
          type="email"
          defaultValue={user.email}
          hidden
          autoComplete="username"
        />
        <FormPassword
          name="current_password"
          autoComplete="current-password"
          label={t("change_password_page.current_password")}
          errors={errors}
          register={register}
        />

        {/* New Password */}
        <Row>
          <Col md={6}>
            <FormPassword
              name="new_password"
              autoComplete="new-password"
              label={t("change_password_page.new_password")}
              errors={errors}
              register={register}
            />
          </Col>
          <Col md={6}>
            <FormPassword
              name="re_new_password"
              label={t("change_password_page.confirm_password")}
              errors={errors}
              autoComplete="new-password"
              register={register}
            />
          </Col>
        </Row>

        {/* Submit Button */}
        <FormGroup>
          <Button
            size="lg"
            className="btn-block"
            type="submit"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" color="light" />
            ) : (
              t("change_password_page.change_password_btn")
            )}
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default ChangePassword;
