import FooterSection from "../../section/FooterSection";
import HeaderSection from "../../section/HeaderSection";

const PageLayout = (props) => {
  return (
    <div className="nk-main content-wrapper">
      <div className="content-body">
        <HeaderSection className="header has-header-main-s1 bg-lighter" />
        {props.children}
      </div>
      <FooterSection className="bg-lighter footer" />
    </div>
  );
};
export default PageLayout;
