import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Spinner, Table } from "reactstrap";
import apiClient from "../../services/ApiClient";

const ExchangeRequests = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchExchangeRequests = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await apiClient.get(`/api/exchange-requests/`);
        setRequests(response.data);
      } catch (err) {
        setError(t("exchange_requests.failed_to_load_error"));
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRequests();
  }, []);

  return (
    <div>
      <h4>{t("exchange_requests.title")}</h4>

      {error && <Alert color="danger" fade={false}>{error}</Alert>}

      {loading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : requests.length > 0 ? (
        <Table bordered responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>{t("exchange_requests.from_currency")}</th>
              <th>{t("exchange_requests.to_currency")}</th>
              <th>{t("exchange_requests.amount")}</th>
              <th>{t("exchange_requests.exchange_rate")}</th>
              <th>{t("exchange_requests.commission")}</th>
              <th>{t("exchange_requests.status")}</th>
              <th>{t("exchange_requests.created_at")}</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {request.from_currency.symbol} {request.from_currency.name} (
                  {request.from_currency.code})
                </td>
                <td>
                  {request.to_currency.symbol} {request.to_currency.name} (
                  {request.to_currency.code})
                </td>
                <td>{parseFloat(request.amount).toFixed(request.from_currency.decimals)}</td>
                <td>{parseFloat(request.exchange_rate).toFixed(4)}</td>
                <td>{parseFloat(request.commission).toFixed(2)}</td>
                <td>
                  <span
                    className={
                      request.status === "Pending"
                        ? "text-warning"
                        : request.status === "Completed"
                          ? "text-success"
                          : "text-danger"
                    }
                  >
                    {request.status}
                  </span>
                </td>
                <td>{new Date(request.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert color="info" fade={false}>{t("exchange_requests.no_requests")}</Alert>
      )}
    </div>
  );
};

export default ExchangeRequests;
