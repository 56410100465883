import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewAltCard } from "../../components/Component";
import PageLayout from "../../layout/page-layout/PageLayout";

const ForgotPasswordSent = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Block className="nk-auth-body wide-xs">
        <BlockHead title="Success" />
        <PreviewAltCard bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">{t("reset_password_page.title")}</BlockTitle>
              <BlockDes>
                <p>{t("reset_password_page.sent")}</p>
                <Link to={`${process.env.PUBLIC_URL}/auth/login`}>
                  <Button color="primary" size="lg">
                    {t("reset_password_page.back_to_login")}
                  </Button>
                </Link>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </PreviewAltCard>
      </Block>
    </PageLayout>
  );
};
export default ForgotPasswordSent;
