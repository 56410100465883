import axios from 'axios';
import i18n from '../i18n';
import { API_SERVER } from '../consts';

// Создаем экземпляр axios
const apiClient = axios.create({
    baseURL: API_SERVER,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Функция для настройки интерцепторов
export const setupInterceptors = (authHeader) => {
    apiClient.interceptors.request.use((config) => {
        const language = i18n.language;
        config.headers['Accept-Language'] = language;
        if (config.url.startsWith('/auth/')) {
            if (authHeader) {
                config.headers['Authorization'] = authHeader;
            }
        }

        return config;
    }, (error) => Promise.reject(error));

    apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401) {
                console.error('Unauthorized, redirecting to login...');
            }
            return Promise.reject(error);
        }
    );
};

export default apiClient;
