import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label } from "reactstrap";
import { AppContext } from "../../context/AppContext";
import { NumberField } from "../fields/NumberField";
import Swap from "../svg/Swap";
import { CurrencyDropdown } from "./dropdown";

const ExchangeForm = ({ from, to, initalAmount }) => {
  const { currencies, quotes } = useContext(AppContext);

  const navigate = useNavigate();
  const [amount, setAmount] = useState(initalAmount || 100);
  const [quote, setQuote] = useState(null);

  const { t } = useTranslation();

  const [fromCurrency, setFromCurrency] = useState(null);
  const [toCurrency, setToCurrency] = useState(null);
  const isDirect = useMemo(() => fromCurrency?.is_crypto, [fromCurrency]);

  const rate = quote ? (!isDirect ? quote.rate : 1 / quote.rate) : 0;
  const price = amount * rate;

  const fiat = useMemo(
    () =>
      currencies.filter((currency) =>
        isDirect ? currency.is_crypto : !currency.is_crypto
      ),
    [currencies, isDirect]
  );
  const crypto = useMemo(
    () =>
      currencies.filter((currency) =>
        isDirect ? !currency.is_crypto : currency.is_crypto
      ),
    [currencies, isDirect]
  );

  useEffect(() => {
    if (currencies && currencies.length > 0) {
      setFromCurrency(
        from ? currencies.find((currency) => currency.code === from) : fiat[0]
      );
      setToCurrency(
        to ? currencies.find((currency) => currency.code === to) : crypto[0]
      );
      setQuote(quotes.get(`${from}/${to}`));
    }
  }, [currencies, from, to]);

  useEffect(() => {
    if (fromCurrency && toCurrency) {
      const newPath = `/exchange/${fromCurrency.code}/${toCurrency.code}`;
      const queryParams = { amount };
      navigate(`${newPath}?${new URLSearchParams(queryParams).toString()}`);
    }
  }, [fromCurrency, toCurrency, amount]); // Обновляем URL при изменении значений

  const submitForm = () => {
    // setModal({ confirm: true, buyCoin: false });
    const newPath = `/request/${fromCurrency.code}/${toCurrency.code}`;
    const queryParams = { amount };
    navigate(`${newPath}?${new URLSearchParams(queryParams).toString()}`);
  };

  if (!currencies || currencies.length === 0) return <div>Loading...</div>;

  return (
    <div className="buysell-block">
      <Form className="buysell-form">
        <div className="buysell-form-block">
          <CurrencyDropdown
            options={fiat}
            currency={fromCurrency}
            label={t("exchange.from_currency")}
            name={"fromCurrency"}
            setCurrency={setFromCurrency}
            otherCurrency={toCurrency}
            className="currency-dropdown"
          />
          <Button
            color="link"
            onClick={() => {
              setFromCurrency(toCurrency);
              setToCurrency(fromCurrency);
            }}
            className="buysell-swap"
          >
            <Swap className="swap-icon" />
          </Button>
          <CurrencyDropdown
            options={crypto}
            currency={toCurrency}
            label={t("exchange.to_currency")}
            name={"toCurrency"}
            setCurrency={setToCurrency}
            otherCurrency={fromCurrency}
            className="currency-dropdown"
          />
        </div>
        <div className="form-group buysell-field">
          <div className="form-label-group">
            <Label className="form-label" for="buysell-amount">
              {t("exchange.amount")}
            </Label>
          </div>
          <div className="form-control-group">
            <Input
              type="number"
              className="form-control form-control-lg form-control-number"
              id="buysell-amount"
              name="bs-amount"
              placeholder="0.055960"
              value={amount}
              onChange={(ev) => setAmount(ev.target.value)}
            />
          </div>
          {rate && (
            <div className="form-note-group">
              <span className="buysell-min form-note-alt">
                {t("exchange.minimum")}:
                <NumberField
                  value={fromCurrency?.min_amount}
                  digits={fromCurrency?.decimals}
                />{" "}
                {fromCurrency?.code}
              </span>
              <span className="buysell-rate form-note-alt">
                1 {toCurrency?.code} ={" "}
                <NumberField value={rate} digits={fromCurrency?.decimals} />{" "}
                {fromCurrency?.code}{" "}
              </span>
            </div>
          )}
          <div className="form-note-group">
            <span className="buysell-min form-note-alt">
              {t("exchange.total_price")}:
              <NumberField value={price} digits={fromCurrency?.decimals} />{" "}
              {fromCurrency?.code}
            </span>
            <span className="buysell-rate form-note-alt">
              1 {fromCurrency?.code} ={" "}
              <NumberField value={1 / rate} digits={toCurrency?.decimals} />{" "}
              {toCurrency?.code}{" "}
            </span>
          </div>
        </div>

        <div className="buysell-field form-action continue-btn">
          <Button
            color="primary"
            className="btn btn-lg btn-block btn-primary"
            onClick={() => submitForm()}
          >
            {t("exchange.continue")}
          </Button>
        </div>

        <div className="form-note text-base text-center">
          <span className="blue-text">{t("exchange.note")}:</span> {t("exchange.fee")}.
        </div>
      </Form>
    </div>
  );
};

export default ExchangeForm;
