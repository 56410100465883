import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { Veriff } from '@veriff/js-sdk';
import classNames from 'classnames';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Block, BlockContent, BlockHead, BlockTitle, PreviewCard } from "../../components/Component";
import Loader from "../../components/loader/loader";
import { VERIFF_API_KEY } from '../../consts';
import { useUserData } from '../../hooks/useUserData';

const KYC = () => {
    const { data: user, loading } = useUserData();
    const { t, i18n } = useTranslation();

    const veriff = Veriff({
        apiKey: VERIFF_API_KEY,
        parentId: 'veriff-root',
        onSession: function (err, response) {
            if (err) {
                toast.error(t('kyc.error'), {
                    position: "top-right",
                });
                console.error(err);
                return;
            }

            // incontext sdk
            createVeriffFrame({
                url: response.verification.url,
                lang: i18n.language,
                onEvent: function (msg) {

                    switch (msg) {
                        case MESSAGES.CANCELED:
                            break;
                        case MESSAGES.FINISHED:
                            window.location.reload();
                            break;
                        default:
                            break;
                    }
                },
            });
        }
    });

    useEffect(() => {
        if (user && user.kyc_status !== 'approved' && user.kyc_status !== 'submitted') {
            veriff.setParams({
                person: {
                    givenName: user.first_name,
                    lastName: user.last_name
                },
                vendorData: `${user.id}`
            });
            veriff.mount({
                formLabel: {
                    givenName: t('kyc.given_name'),
                    lastName: t('kyc.last_name'),
                },
                submitBtnText: t('kyc.get_verified_btn'),
                loadingText: t('kyc.please_wait'),
            });
        }

        return () => {
            
        }
        // this renders once when there isn't an input
    }, [user, i18n.language, t]);


    if (loading) {
        return <Loader />
    }

    const statusColors = {
        'approved': 'text-success',
        'submitted': 'text-warning',
        'rejected': 'text-danger'
    }

    const color = statusColors[user?.kyc_status];

    return (
        <Block className="nk-block">
            <BlockHead>
                <BlockTitle tag="h4">{t('kyc.title')}</BlockTitle>
            </BlockHead>
            <PreviewCard>
                <BlockContent>
                    <div className="mb-4">
                        {t('kyc.status')} <b className={classNames(color)}>{user?.kyc_status}</b>
                    </div>
                    <div id="veriff-root"></div>
                </BlockContent>
            </PreviewCard>
        </Block>
    );
};

export default KYC;
