import React, { useCallback, useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import { LangDropdown } from "../../components/Component";
import i18n, { languages } from "../../i18n";

export const LanguageHead = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prevState) => !prevState)
  };
  const changeLanguage = useCallback((lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  }, []);

  const language = languages.find((item) => item.code === i18n.language);
  

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon language-icon"
      >
        <img src={'/assets/flags/' + language.code + '.svg'} alt={language.name} className="language-flag-img"/>
      </DropdownToggle>
      <LangDropdown className="dropdown-menu-s1" changeLanguage={changeLanguage} />
    </Dropdown>
  );
};
