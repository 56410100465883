import React from "react";
import { Col, Container, Row } from "reactstrap";
import { ImageBlock } from "../components/images/Images";
import {
  Service,
  ServiceIcon,
  ServiceText,
} from "../components/service/Service";
import {
  BarChart,
  Cloud,
  Contents,
  HelpDesk,
  Vision,
} from "../components/svg/Icon";
import { TextBlock, TitleH2 } from "../components/textBlock/TextBlock";
import { Section, SectionContant } from "../layout/section/Section";
import { useTranslation } from "react-i18next";

const GetStartedSection = (props) => {
  const { t } = useTranslation();
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="justify-content-center text-center get-started-title-row">
          <Col xl="6" lg="7" md="8">
            <TextBlock className="is-compact">
              <ImageBlock className="h-150px mb-4">
                <HelpDesk />
              </ImageBlock>
              <TitleH2>{t("get_started_section.title")}</TitleH2>
            </TextBlock>
          </Col>
        </Row>
        <SectionContant>
          <Row className="justify-content-center text-center g-gs">
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <BarChart />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">
                    {t("get_started_section.view_live_rates_title")}
                  </h4>
                  <p>{t("get_started_section.view_live_rates_text")}</p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Contents />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">
                    {t("get_started_section.register_online_title")}
                  </h4>
                  <p>{t("get_started_section.register_online_text")}</p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Cloud />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">
                    {t("get_started_section.exchange_securely_title")}
                  </h4>
                  <p>{t("get_started_section.exchange_securely_text")}</p>
                </ServiceText>
              </Service>
            </Col>
            <Col sm="7" md="5" lg="3">
              <Service>
                <ServiceIcon className="styled-icon styled-icon-s4 styled-icon-6x circle text-primary">
                  <Vision />
                </ServiceIcon>
                <ServiceText>
                  <h4 className="title">
                    {t("get_started_section.visit_in_person_title")}
                  </h4>
                  <p>{t("get_started_section.visit_in_person_text")}</p>
                </ServiceText>
              </Service>
            </Col>
          </Row>
        </SectionContant>
      </Container>
    </Section>
  );
};

export default GetStartedSection;
